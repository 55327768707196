@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
*,
html,
body {
  font-family: 'Roboto', sans-serif;
}
main {
  background-color: black;
}
p {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#parent {
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.3rem;
  color: white;
  position: relative;
}
#border {
  border-bottom: solid 3px rgba(71, 174, 86, 0.75);
  position: absolute;
  right: -7px;
  width: 20px;
}
/* Animation */
#parent {
  animation: animated-text 2s steps(30, end) 1s 1 normal both;
}
#border {
  animation: animated-cursor 600ms steps(30, end) infinite;
}
/* text animation */

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* cursor animations */

@keyframes animated-cursor {
  from {
    border-bottom-color: rgba(0, 255, 0, 0.75);
  }
  to {
    border-bottom-color: transparent;
  }
}

/******Loading Page******/
.splash {
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: white;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.services p {
  font-size: 1.2rem;
}
.services p:hover,
.services p:focus {
  color: #47ae56;
  cursor: pointer;
}

.particles {
  max-width: 100vw;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
.products h3 {
  color: white;
  padding-top: 5%;
}
.products img {
  width: 250px;
  height: 250px;
  transition: all 0.3s ease-in-out;
}
.products img:hover {
  border: 5px solid #47ae56;
}

.talent-button {
  background-color: #47ae56;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 0.5em 1em;
  width: 200px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
.social-icons {
  background-color: rgba(57, 169, 74, 0.3);
  width: 80px;
  height: 80px;
  display: inline;
  border-radius: 100%;
  padding: 1.5em 1em;
}
.cursor {
  width: 20px;
  height: 20px;
  background-color: white;
  position: fixed;
  border-radius: 50%;
}
.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #47ae56;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 900;
  border: none;
}
.media span {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
}
.media-paragraph span {
  color: #47ae56;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.media h5 {
  color: white;
  padding-top: 5%;
}
