.cls-1 {
  font-size: 20.79px;
}
.cls-1 {
  fill: #fff;
  pointer-events: none;
}
.cls-3,
.cls-2 {
  fill: #fff;
  opacity: 1;
}
.cls-1,
.cls-2 {
  font-family: Lato-Regular, Lato;
}
.cls-2 {
  font-size: 25.98px;
}
.cls-4 {
  fill: #39a94a;
}
.cls-5 {
  opacity: 0.25;
}
.cls-6 {
  fill: #39a94a;
}
.quality-desc,
.ethics-desc,
.integrity-desc,
.sustain-desc,
.excellence-desc,
.reliability-desc {
  opacity: 0;
}
