.brandHover {
  width: 160px;
  height: 160px;

  filter: grayscale(1);
}

.brandHover:hover,
.brandHover:focus {
  filter: grayscale(0);
}
.clientImage {
  background-color: rgba(255, 255, 255, 0.5);
  width: 50%;
  width: 160px;
  height: 160px;
}

.image {
  display: block;
  width: 100%;
  padding: 10%;
  height: auto;
}

.clientImage:hover {
  opacity: 1;
  background-color: rgba(0, 255, 0, 0.2);
  border: 2px solid rgba(0, 255, 0, 0.75);
}

.client-details {
  color: white;
  font-size: 20px;
  text-align: center;
  height: 50px;
}
.client-details p {
  margin-bottom: 0rem !important;
}
