.form-control,
.request-form .form-control {
  background-color: transparent;
  border-radius: 50px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: white;
}
.form-control::placeholder,
.request-form .form-control::placeholder {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: white;
  opacity: 1;
}
