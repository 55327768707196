.custom-nav {
  background-color: rgba(0, 0, 0, 0.767);
  height: 10vh;
}

.menu-links,
.navbar-light .navbar-nav .nav-link {
  color: white !important;
  font-size: 0.8rem;
  margin-right: 2em;
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s;
}
.dropdown-menu {
  background-color: #626362 !important;
}
.menu-links:active {
  color: #47ae56;
}
.menu-links::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #47ae56;
  transition: width 0.3s;
}
.menu-links:hover::after {
  width: 100%;
}
.menu-links:hover,
.menu-links:focus {
  color: #47ae56 !important;
}
footer h4 {
  color: #47ae56;
}
footer p {
  color: white;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1rem;
}
footer p:hover,
footer p:focus {
  color: #47ae56;
}
footer i {
  color: #47ae56;
  padding-right: 10%;
  padding-top: 0.5%;
}
footer .social i {
  padding-right: 2%;
  padding-left: 2%;
  font-size: x-large;
}
.btn-truck {
  background-color: #47ae56;
  border-radius: 50px;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delivery {
  background-color: #47ae56;
  border-radius: 50px;
  width: 50px;
  height: 30px;
  padding: 0.25rem 0.5rem;
  color: white;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.delivery > div {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  border-radius: 100%;
}
.delivery:hover {
  width: 150px;
}
.delivery > div > img,
.delivery > div > span {
  margin: auto;
  padding: 0.3rem 0.5rem;
}
.delivery > div > span {
  opacity: 0;
  font-size: 0.8rem;
}

.delivery:hover > div > img {
  opacity: 0;
}

.delivery:hover > div > span {
  opacity: 1;
}
.copy a:hover {
  text-decoration: none;
}
.copy p {
  font-size: 0.7rem;
}
.copy img {
  width: 28px;
  height: 25px;
}
