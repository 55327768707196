.our-story {
  margin-bottom: 10%;
}
.our-story p {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  width: 50%;
}
.teams .img-overlay {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
}
.teams .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #39a94a;
  border-radius: 50%;
}
.teams .img-overlay:hover .overlay {
  opacity: 1;
}
.text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.csr p {
  width: 70%;
}
.teams h5 {
  color: white;
  text-align: center;
  margin-top: 10%;
}
