.background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
}
.black {
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.5);
}
.banner {
  width: 100%;
}
.green-banner {
  width: 100%;
  height: 40vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(71, 174, 86, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: fadeIn 1s;
}
h1 {
  color: #47ae56;
  font-weight: 400;
  font-size: 4rem;
  font-family: 'Lato', sans-serif;
  text-transform: capitalize;
  text-align: inter-character;
}
.creative-text {
  width: 100%;
}

.creative h1:after {
  content: '';
  display: inline-block;
}
.creative p {
  color: white;
  font-weight: 200;
  font-size: 1.4rem;
}

.whoweare h5 {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.whoweare p:nth-child(2) {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-align: justify;
}
.whoweare p:nth-child(3) {
  color: #47ae56;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  text-align: justify;
  font-style: italic;
}
.hovereffect {
  margin: auto;
  padding: 2em 2em;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 2px solid transparent;
  background-clip: content-box;
  transition: transform 0.5s ease, border 0.5s ease;
}
.our-edge h6 {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  text-align: center;
}
.our-edge p {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-align: center;
}
.brandHover {
  width: 160px;
  height: 160px;

  background-color: rgba(255, 255, 255, 0.5);
  filter: grayscale(1);
}
.brandHover img {
  width: 100%;
  padding: 10%;
}
.brandHover:hover,
.brandHover:focus {
  background-color: rgba(0, 255, 0, 0.2);
  border: 2px solid rgba(0, 255, 0, 0.75);
  filter: grayscale(0);
}
.hovereffect:hover,
.hovereffect:focus {
  border: 10px solid #47ae56;
}
.clients p {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
.carousel-item span {
  position: relative;
  display: inline-block;
}
.carousel-inner {
  overflow: visible !important;
}
.carousel-item span::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 50px;
}
.greenbackground {
  background-color: rgba(0, 255, 0, 0.2);
  border: 2px solid rgba(0, 255, 0, 0.75);
  transform: scale(0.7);
}
.carousel-item span img {
  position: relative;
  height: 100%;
  transform: scale(1.6);
  padding-bottom: 12.5%;
}
.trust p:nth-child(1) {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-align: center;
}
.trust p:nth-child(2) {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}
.trust p:nth-child(3) {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  text-align: center;
}
.stars {
  text-align: center;
}
.clients h4 {
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}
